import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; 
import Navbar from "../headers_footer/navbar";
import ItemHistory from "../itemhistory";
import WishList from "../components/WishList";
import EditProfile from "../EditProfile";
import './Profile.css';


const Profile = () => {

  const naviGateEdit = useNavigate()
  
  const EditProfile = () => {
  naviGateEdit('/EditProfile');

  }

  
  const [loggedInUser, setLoggedInUser] = useState(null);
  
  useEffect(() => {
    const storedUser = localStorage.getItem('loggedInUser');
    if (storedUser) {
      setLoggedInUser(JSON.parse(storedUser));
    }
  }, []);

  const navigate = useNavigate();

  const navigateToItemHistory = () => {
    navigate('/ItemHistory');
  };

  const navigateToWishlist = () => {
    navigate('/WishList');
  };

  return (

    <div>
      <Navbar />
      <div className="profile_navi">
        <li onClick={navigateToItemHistory}>
          <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/drpdwn-wallet.svg" alt="Item History" />
          Item History
        </li>
        <li onClick={navigateToWishlist}>
          <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/drpdwn-order-history.svg" alt="Wish List" />
          Wish List
        </li>
      </div>

      <div className="n_profile">
        
        {loggedInUser && loggedInUser.name && (
          <>

            <div className="circle_pr">
              <h1>{loggedInUser.name.charAt(0)}</h1>
            </div>


            <div className="edit_flex">

           <div>
           <h1>{loggedInUser.name}</h1>
           <div className="flex_prf_">
             <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/profile-email.svg" alt="Email Icon" />
             <p>{loggedInUser.email}</p>
           </div>      
           </div>
              
            <h3 onClick={EditProfile}>Edit</h3>

            </div>

            
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
