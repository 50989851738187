import React from "react";
import './favPick.css'

const FavPick = () => {

return (

<div>

<div className="unique_gf">
{/* ---- */}

<div className="img-flex_withflex">
{/* 
<img src="https://cdn.igp.com/image/upload/assets/svg-icons/unique_gifts_igp_d_title.svg"></img> */}

<div>
<h1>Unique Gifts Online</h1>
<p>Curated to make every special moment a celebration</p>
</div>
{/* -- */}
</div>

{/* --- */}

<div className="img_flex">
<h1>Surprises for Every Celebration</h1>
<div>
    {/* <img src="https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/o/r/n/xxl-21440436-kook-n-keech-original-imagsw8ugkzzwmxg.jpeg?q=70"></img>
    <img src="https://rukminim2.flixcart.com/image/612/612/l111lzk0/t-shirt/y/o/s/xl-16190040-kook-n-keech-original-imagczgfsf8qexac.jpeg?q=70"></img>
    <img src="https://rukminim2.flixcart.com/image/612/612/koixwnk0/t-shirt/f/7/a/xl-12956150-kook-n-keech-original-imag2ys9djggtbth.jpeg?q=70"></img>
    <img src="https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/a/n/7/m-10926076-powerpuff-girls-by-kook-n-keech-original-imagtsctufryhh6k.jpeg?q=70"></img>
    <img src="https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/6/x/o/xl-10926666-kook-n-keech-original-imagtsdzzjazwtsa.jpeg?q=70"></img>
    <img src="https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/u/3/w/xs-21440586-kook-n-keech-disney-original-imagufwycrukzzk8.jpeg?q=70"></img> */}
</div>
{/* --- */}
</div>

</div>
{/* ---- */}


<div className="be_gft_rdy">
<h1>Be Gift Ready Always
</h1>
<div className="Flex_pic">
{/* <img src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/r/e/m/-original-imagsfdvuffvqx4x.jpeg?q=70"></img>
<img src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/i/n/z/11-rpd103-neongreen-rapidbox-green2-original-imah2dzbeqcnueth.jpeg?q=70"></img>
<img src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/q/d/c/-original-imahfxvau7mtf3gz.jpeg?q=70"></img>
<img src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/j/e/f/6-assg1290-6-abros-offwhite-mint-original-imagxhwypwkugffg.jpeg?q=70"></img> */}
</div>
</div>

</div>

)


}

export default FavPick;