import React from "react";
import './bottomFavcomp.css'
import midimg1 from '../Slider/mid_img.png';



const BottomFavcomp = () => {

return (

<div>

<img src={midimg1} className="img_ara"></img>

</div>


)


}

export default BottomFavcomp;