import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import './navbar.css';
import ItemHistory from "../itemhistory";
import Registeration from "../RegisterationsLogin/Registeration";
import ProductList from "../components/ProductList";
import WishList from "../components/WishList";
import Profile from "../RegisterationsLogin/Profile";
import logoS_img from '../Logo/logo_s.png';


const Navbar = () => {
  
  const [loggedInUser, setLoggedInUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    
    const storedUser = localStorage.getItem('loggedInUser');
    if (storedUser) {
      setLoggedInUser(JSON.parse(storedUser));
    }
  }, []);


  useEffect(() => {

    if (location.state && location.state.loggedInUser) {
      const user = location.state.loggedInUser;
      setLoggedInUser(user);
      localStorage.setItem('loggedInUser', JSON.stringify(user));
    }
  }, [location.state]);


  const navigateEcart = () => {
    navigate('/Ecart');
  };


  
  const logout = () => {
    setLoggedInUser(null);
    localStorage.removeItem('loggedInUser');
    navigate('/ProductList');
  };


  const navi = useNavigate()
  const naviProductPage = () => {
  navi('/ProductList')
  }

  

  const naviReg = useNavigate()
  const naviRegist = () => {
  naviReg('/Registeration')
  }


  const naviorderhistory = useNavigate()
  const orderhistory = () => {
  naviorderhistory('/ItemHistory')
  }

  const naviheartNavi = useNavigate()
  const heartNavi = () => {
  naviheartNavi('/WishList')
  }

  
  const profileNavi  = useNavigate()
  const profileClick = () => {
  profileNavi('/Profile')
  }


  
  
  const [isblock,setisblock] = useState(true)
  const navigateUser = () => {
  setisblock(!isblock)
  };

  const clickOpen = () => {  
  setisblock(!isblock)
  }

  return (

    <div>
      
      <nav>

      <i className="fa fa-bars" onClick={clickOpen}></i>
        <img onClick={naviProductPage} src="https://www.snitch.co.in/cdn/shop/files/SNITCH_LOGO_NEW_BLACK.png?v=1721457834" className="logo_img"></img> 
        
        <div className="flex_nav">
        <div className="search-container">
          <input type="text" placeholder="Search for gifts" />
          <i className="fa fa-search search-icon"></i>
        </div>
        {/* --- */}
        
          <img className="Imgs_nav" src="https://cdn.igp.com/raw/upload/assets/svg-icons/reminder-icon.svg" alt="reminder"/>
          <img className="Imgs_nav" src="https://cdn.igp.com/raw/upload/assets/svg-icons/shortlist-new.svg" alt="shortlist" onClick={heartNavi}/>
          <img className="Imgs_nav fa fa-shopping-cart cart_icon" src="https://cdn.igp.com/raw/upload/assets/svg-icons/cart-new.svg"  onClick={navigateEcart} alt="cart"/>
          <img className="user_mm" onClick={navigateUser} src="https://cdn.igp.com/raw/upload/assets/svg-icons/user-logo-new.svg" alt="user"/>
          
          <div>

            {loggedInUser ? (
              
              <div className={`acc_div ${isblock ? 'none' : 'block'}`}>            
              
                <div className="flex_profile">
                  <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/drpdwn-user-logo.svg" 
                  className="img_pfpf" alt="profile"/>
                  <div>
                    <h4>{loggedInUser.name}</h4>
                    <p>{loggedInUser.email}</p>
                  </div> 
                </div>
                <div className="flex_div_prfle">
                  <div>
                    <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/drpdwn-user-logo.svg" 
                    className="img_pf" alt="profile"/>
                    <p onClick={profileClick}>Profile</p>
                  </div>
                  <div>
                    <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/track-order-new.svg" className="img_pf" alt="track order"/>
                    <p>Track Order</p>
                  </div>
                  <div>
                    <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/drpdwn-order-history.svg" className="img_pf" alt="order history"/>
                    <p onClick={orderhistory}>Order History</p>
                  </div>

                  <div >
                    <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/drpdwn-wallet.svg" className="img_pf" alt="contact us"/>
                    <p>Wallet</p>
                  </div>

                  <div className="contact-us">
                    <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/terms-and-condition-new.svg" className="img_pf" alt="privacy policy"/>
                    <p>Contact us</p>
                  </div>
                  <div>
                    <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/terms-and-condition-new.svg" className="img_pf" alt="privacy policy"/>
                    <p>Privacy Policy</p>
                  </div>
                  <div>
                    <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/terms-and-condition-new.svg" className="img_pf" alt="privacy policy"/>
                    <p>Terms & Policy</p>
                  </div>
                  <div className="sign_out">
                  <img src="https://cdn.igp.com/raw/upload/assets/svg-icons/Icon-Logout.svg" className="img_pf" alt="logout"/>
                  <p onClick={logout}>Sign out</p>
                  </div>
                </div>
              </div>
            ):(

              <h3 onClick={naviRegist}>Registeration</h3>
            )
            }

          </div>
        </div>
      </nav>

    </div>
  );
};

export default Navbar;
