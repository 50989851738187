const storedCart = JSON.parse(localStorage.getItem('cart'));
const storedQuantities = JSON.parse(localStorage.getItem('quantities'));

const initialState = {
  cart: storedCart || [],
};


const storedCartk = JSON.parse(localStorage.getItem('cart'));

if (Array.isArray(storedCart)) {
  storedCart.forEach(item => {
    console.log(item);
  });
} else {
  console.log('storedCart is not an array or is empty');
}


const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const updatedCartAdd = [...state.cart, action.payload];
      const updatedQuantitiesAdd = [...(storedQuantities || []), 1]; // Initialize quantities if null
      localStorage.setItem('cart', JSON.stringify(updatedCartAdd));
      localStorage.setItem('quantities', JSON.stringify(updatedQuantitiesAdd));
      return {
        ...state,
        cart: updatedCartAdd,
      };
      
    case 'REMOVE_FROM_CART':
      const updatedCartRemove = state.cart.filter((item, index) => index !== action.payload);
      const updatedQuantitiesRemove = (storedQuantities || []).filter((_, index) => index !== action.payload);
      localStorage.setItem('cart', JSON.stringify(updatedCartRemove));
      localStorage.setItem('quantities', JSON.stringify(updatedQuantitiesRemove));
      return {
        ...state,
        cart: updatedCartRemove,
      };
    default:
      return state;
  }
};

export default rootReducer;
