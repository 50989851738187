import React, { useState } from "react";
import './form.css'
import { useNavigate } from "react-router";
import Login from "../Login/Login";
import Navbar from "../headers_footer/navbar";

const Registeration = () => {
  
  const [formdata, setformdata] = useState({
    name: '',
    email: '',
    password: '',
    mobileno: '',
  });

  
  const registerhanlder = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const registeration = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://103.149.68.57/registerationPost', { // Change to http if SSL is an issue
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formdata),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
  
      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error('Error message:', error);
    }
  };
  
  
  const navigate = useNavigate();
  const Signin = () => {
    navigate('/Login');
  };

return (

<div>

<Navbar></Navbar>

<div className="flex_regis_Container">

      <img src="https://img.freepik.com/free-vector/mobile-browsers-concept-illustration_114360-1267.jpg?t=st=1718540656~exp=1718544256~hmac=35016f23358fa7dccd0174b0a1db4a3d83ab6e23fb740b638671dc16ed9f23a0&w=740"></img>      
      <form onSubmit={registeration}  className="register_form">

        <h1>Sign up</h1>
        <h3>Already have an account? <span onClick={Signin}>Sign in</span></h3>
        <div className="flex_form">
          <div>
            <h3>Full name*</h3>
            <input placeholder="Full name" name="name" value={formdata.name} onChange={registerhanlder}></input>
          </div>
          <div>
            <h3>Email*</h3>
            <input placeholder="Email" name="email" value={formdata.email} onChange={registerhanlder}></input>
          </div>
        </div>
        <div className="flex_form">
          <div>
            <h3>Password*</h3>
            <input placeholder="Password" name="password" value={formdata.password} onChange={registerhanlder}></input>
          </div>
          <div>
            <h3>Mobile*</h3>
            <input placeholder="Mobile no" name="mobileno" value={formdata.mobileno} onChange={registerhanlder}></input>
          </div>
        </div>
        <button type="submit">SING UP</button>
      </form>
</div>

    </div>
  );
};

export default Registeration;
