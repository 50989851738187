import React, { useState } from "react";
import { useNavigate } from "react-router";
import './Login.css';
import Navbar from "../headers_footer/navbar";


const Login = () => {

  const [formdata, setformdata] = useState({
    email: '',
    password: '',
  });

  const [loggedInUser, setLoggedInUser] = useState(null);

  const inputgetHanlder = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  }

  const naviGate = useNavigate();
  const loginHanlder = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/fetchlogin', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(formdata)
      });

      const data = await response.json();
      const user = data.find(user => 
        user.email === formdata.email && user.password === formdata.password
      );

      if (user) {
        setLoggedInUser(user);
        naviGate('/ProductList', { state: { loggedInUser: user } });
      } else {
        console.log('Invalid credentials');
      }
    } catch (error) {
      console.log('Error', error);
    }
  }

  return (

    <div>

    <Navbar></Navbar>

    <div className="flex_login">

    <div>
      <img src="https://img.freepik.com/free-vector/young-woman-checking-security-password-virsual-folder-isometric-illustration_1150-37274.jpg?t=st=1718541833~exp=1718545433~hmac=b7e1328b7587b587bbc48377fa90f6396398578f70944848b5a22eefe782474a&w=826"></img>
    </div>
      <form onSubmit={loginHanlder} id="logged_form">
        <h1 >Customer Login</h1>
        <h2>Username</h2>
        <input placeholder="Email" name="email" onChange={inputgetHanlder} value={formdata.email} />
        <h2>Password</h2>
        <input placeholder="Password" name="password" onChange={inputgetHanlder} value={formdata.password} type="password" /><br />
        <button type="submit">Login</button>
      </form>
    

      </div>

      
      {loggedInUser && (
        <div>
          <h2>Your Account</h2>
          <p>Email: {loggedInUser.email}</p>
          <p>Name: {loggedInUser.name}</p>
          <p>Mobile no: {loggedInUser.mobileno}</p>
        </div>
      )}
    </div>
  );
}

export default Login;
