import logo from './logo.svg';
import './App.css';
import ProductList from './components/ProductList';
import { Provider } from 'react-redux';
import Cart from './components/Cart';
import store from './store';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Ecart from './Pages/Ecart';
import Registeration from './RegisterationsLogin/Registeration';
import Login from './Login/Login';
import Header from './headers_footer/header';
import ProductDetails from './components/ProductDetails';
import Dashboard from './Dashboard';
import OwnerDashboard from './ownerDashboard';
import ItemHistory from './itemhistory';
import Profile from './RegisterationsLogin/Profile';
import WishList from './components/WishList';
import Address from './Login/Address';
import EditProfile from './EditProfile';


function App() {
  
  return (
    
    <div className="App">
    
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path='/Login' element={<Login />} />
            <Route path='/ProductList' element={<ProductList />} />
            <Route path='/' element={<ProductList />} />
            <Route path='/product/:id' element={<ProductDetails />} />
            <Route path='/Ecart' element={<Ecart />} />
            <Route path='/Registeration' element={<Registeration />} />
            <Route path='/Header' element={<Header />} />
            <Route path='/Dashboard' element={<Dashboard />} />
            <Route path='/OwnerDashboard' element={<OwnerDashboard />} />
            <Route path='/ItemHistory' element={<ItemHistory />} />
            <Route path='/WishList' element={<WishList />} />
            <Route path='/Profile' element={<Profile />} />
            <Route path='/Address' element={<Address />} />
            <Route path='/EditProfile' element={<EditProfile />} />
          </Routes>
        </Router>
      </Provider>
      
    </div>
  );
}

export default App;
