import React from "react";


const OwnerDashboard = () => {

return (


<div>

<h1>Jay</h1>


</div>


)


}

export default OwnerDashboard;