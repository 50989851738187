import React from "react";


const Dashboard = () => {


const submitAdmit = () => {



}



return (

<div>


<form>

<h1>Admin Login</h1>
<input placeholder="Username"></input> <br></br>
<input placeholder="Username"></input> <br></br>
<button onClick={submitAdmit}>Login</button>

</form>


</div>

)

}

export default Dashboard;