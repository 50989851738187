import React, { useState, useEffect } from 'react';
import Navbar from '../headers_footer/navbar';
import './WishList.css';
import Header from '../headers_footer/header';
import axios from 'axios';


const WishList = () => {

  const [wishlist, setWishlist] = useState([]);
  const [productData, setProductData] = useState([]);
  
  useEffect(() => {
    const storedWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    setWishlist(storedWishlist);
  }, []);

  const handleRemove = (index) => {
    const newWishlist = wishlist.filter((_, i) => i !== index);
    setWishlist(newWishlist);
    localStorage.setItem('wishlist', JSON.stringify(newWishlist));
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get('http://localhost:3001/fetchProductslist');
        setProductData(response.data);
      } catch (error) {
        console.log('Error fetching products:', error);
      }
    };

    fetchProductData();
  }, []);

  const getProductImagePath = (productId) => {
    const product = productData.find(p => p.id === productId);
    return product ? `http://localhost:3001${product.file_path}` : '';
  };


return (

<div>

<Navbar />

<ul className='wishlist'>

{wishlist && wishlist.length > 0 ? (

  <ul className='flex_whislist'>

    {wishlist.map((item, index) => (

      <li key={index} style={{ listStyle: 'none' }} className='wishlist_item'>
      
        <div className='wishlist_details'>
          <div>
          <h3>{item.name}</h3>
          {/* <img src={item.img} alt={item.name} /> */}
          <img
                      src={getProductImagePath(item.id)}
                      alt={item.name}
                      className='cart-item-image'
          />
          <li>Price: ₹{item.price}</li>
          </div>
          {/* -- */}
          
          <div className='li_trash_hrt'>
            <i onClick={() => handleRemove(index)} className='fa fa-trash'></i> <br></br>
          </div>
        </div>

      </li>
    ))}
    
  </ul>
) : (

    <div>
    <img src='https://cdn.igp.com/raw/upload/assets/svg-icons/empty-wishlist-new.svg' alt='Empty Wishlist'  className='wish-listImg'/>
    <div className='div_flex'>
    <h1>Oops!</h1>
    <p>Your wishlist seems to be empty. <br></br>
    Lets find something for you.</p>
    </div>

  </div>

)}  
        
</ul>


<Header />

</div>


);

};

export default WishList;
