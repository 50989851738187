import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Navbar from "./headers_footer/navbar";
import FavPick from "./componentsBoot/favPick";
import './ItemHistory.css'
import Header from "./headers_footer/header";


const ItemHistory = () => {

  const [loggedInUser, setLoggedInUser] = useState(null);
  const location = useLocation();
  const [quantities, setQuantities] = useState([]);

  // Load user and product location from localStorage on mount
  useEffect(() => {
    const storedUser = localStorage.getItem('loggedInUser');
    if (storedUser) {
      setLoggedInUser(JSON.parse(storedUser));
    }

    if (location.state && location.state.loggedInUser) {
      const user = location.state.loggedInUser;
      setLoggedInUser(user);
      localStorage.setItem('loggedInUser', JSON.stringify(user));
    }
  }, [location.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchProductHistory = await fetch('http://localhost:5000/fetchProductHistory');
        const data = await fetchProductHistory.json();

        
        if (loggedInUser) {
          const userProducts = data.filter(item => item.user_mobile === loggedInUser.mobileno);
          setQuantities(userProducts);
        }

      } catch (error) {
        console.log('Error unsuccessful', error);
      }
    };

    fetchData();
  }, [loggedInUser]);

  return (
  <div>

<Navbar></Navbar>

<div>


<div id="main_centr">
<h1>Purchase History</h1>
{quantities.length > 0 ? (
  quantities.map((item, index) => (
    <div key={index} id="flexHisstry">
      <img src={item.img}></img>
      <li>{item.name}</li>
      <li>{item.quantity}</li>

        <div id="flex_historyPrice">
        <i className="fa fa-inr"></i>
        <p>{item.price}</p>
        </div>
      
    </div>
  ))
) : (
  <p>No product history available for the logged-in user.</p>
)}

</div>

</div>

<Header></Header>

</div>

);
};

export default ItemHistory;
