import React from "react";
import Cart from "../components/Cart";


const Ecart = () => {

return (


<div>

<Cart></Cart>

</div>


)


}

export default Ecart;