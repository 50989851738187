import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../headers_footer/navbar';
import Slider from '../slider';
import Header from '../headers_footer/header';
// import FavPick from '../componentsBoot/favPick';
import BottomFavcomp from '../componentsBoot/bottomFavcomp';
import SlideImg from '../componentsBoot/SlideImg';
import './ProductListmodule.css';
import axios from 'axios';


const ProductList = () => {

  const [arrayStore, setArrayStore] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get('http://localhost:3001/fetchProductslist');
        setArrayStore(response.data);
      } catch (error) {
        console.log('Error fetching products:', error);
      }
    };

    fetchProduct();
  }, []);

  console.log(arrayStore)


    const sendToWishlist = (product) => {
    let wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    wishlist.push(product);
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
    };

  return (

    <div>
      
      <Navbar />
      <Slider />

      <div className='be_gft'>
        
      </div>

      <div className='bst_sell'>
        <h1>Best Selling Gifts</h1>
        <p>Packed with Love</p>
      </div>

      
      <BottomFavcomp />

      <div className='flex_productlist'>
    
        {arrayStore.map((productlist, index) => (
          
          <div key={index} style={{ listStyle: 'none' }}
          
          className='produclist_divContainer'>
            <i
              onClick={() => sendToWishlist(productlist)}
              className='fa fa-heart'
              style={{ zIndex: '999', position: 'absolute' }}
            ></i>
            
            <Link to={`/product/${index}`}>
              <img
                src={`http://localhost:3001${productlist.file_path}`} 
                alt={productlist.name} 
              />
            </Link>
          
            <div className='padding_contain'>
              <div className='star_icon'>
                <div>
                  {/* <i className='fa fa-star'></i>
                  <i className='fa fa-star'></i>
                  <i className='fa fa-star'></i>
                  <i className='fa fa-star'></i> */}
                </div>
                <div>
                  <i>4.5(2,213)</i>
                </div>
              </div>
              <p>{productlist.name}</p>
              <div className='flex_inr'>
                <div className='first_div'>
                  <li className='fa fa-inr'></li>
                  <li>{productlist.price}</li>
                </div>
                <div>
                  <i className='nx_dy_dlvry'>Next day delivery</i>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <SlideImg /> */}
      <Header />
    </div>
  );
};

export default ProductList;
