import React from "react";

const EditProfile = () => {

return (


<div>

<h1>Edit</h1>

</div>


)


}

export default EditProfile;