import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { addToCart } from '../action/action';
import Navbar from '../headers_footer/navbar';
import Header from '../headers_footer/header';
import './ProductDetails.css';


const ProductDetails = ({ addToCart }) => {
  
  const { id } = useParams();

  const productIndex = parseInt(id, 10);
  const [arrayStore, setArrayStore] = useState(null);
  
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get('http://localhost:3001/fetchProductslist');
        const data = response.data;
        const product = data.find((_, index) => index === productIndex);
        setArrayStore(product);
      } catch (error) {
        console.log('Error fetching products:', error);
      }
    };
    
    fetchProduct();
  }, [productIndex]);


  const handleAddToCart = () => {
    if (arrayStore) {
      addToCart(arrayStore);
    }
  };

  if (!arrayStore) {
    return <div>Product not found</div>;
  }

  
  return (

    <div>

      <Navbar />
      
      <div className="product-details">

        <div> 
        
          <h1>{arrayStore.name}</h1>

               <img className='img_details'
                src={`http://localhost:3001${arrayStore.file_path}`} 
                alt={arrayStore.name} 
              />
        </div>
        
        <div className='second_div'>
          <section>
          <h1>{arrayStore.name}</h1>
            {/* <img src='https://cdn-icons-png.flaticon.com/512/66/66841.png' className='dlivery_img' alt="Delivery" /> */}
          </section>
          {/* <p>Description of the product goes here.</p> */}

          <div className='star_icon'>
            <span>
              {/* <i className='fa fa-star'></i>
              <i className='fa fa-star'></i>
              <i className='fa fa-star'></i>
              <i className='fa fa-star'></i> */}
            </span>
            <i>4.5(2,213)</i>
          </div>

          <p style={{ fontSize: '1.5rem', margin: '-.3em 0em .4em 0em' }}>INR ₹{arrayStore.price}</p>

          <div>
                
            {/* <i>Large</i>
            <input type='radio' name='size' value='large' />
            <i>Medium</i>
            <input type='radio' name='size' value='medium' />
            <i>Small</i>
            <input type='radio' name='size' value='small' /> */}
        
            <div className='flx_prdct'>
              <img
                src={`http://localhost:3001${arrayStore.file_path}`} 
                alt={arrayStore.name} 
              />
              {/* --- */}
              <img
                src={`http://localhost:3001${arrayStore.file_path}`} 
                alt={arrayStore.name} 
              />
              {/* -- */}
            </div>

            <div className='size_chart'>
            <button className='size_m'>S</button>
            <button className='size_m'>M</button>
            <button className='size_m'>XL</button>
            <button className='size_m'>XXL</button>
            </div>
            {/* -- */}  
            
            
            <button onClick={handleAddToCart} className='add_crt'>ADD TO CART</button> <br></br>
            <button className='go-toCart'>GO TO CART</button>
            
          </div>
        </div>
      </div>
      <Header />
    </div>
  );
};

export default connect(null, { addToCart })(ProductDetails);
