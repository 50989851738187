import React from "react";
import Dashboard from "../Dashboard";
import './header.css';
import { Navigate, useNavigate } from "react-router";


const Header = () => {

return (

<div>


<header>

<div className="div_header">

<div className="header_dv">
    <img src="https://www.snitch.co.in/cdn/shop/files/SNITCH_LOGO_NEW_BLACK.png?v=1721457834"></img>
</div>

{/* --- */}
<ul>
    <h2>About us</h2>
    <li>About us</li>
    <li>Careers us</li>
    <li>Contact Us</li>
    <li>In News</li>
</ul>
{/* --- */}
<ul>
    <h2>Know Us</h2>
    <li>Out Story</li>
    <li>Contact Us</li>
</ul>
{/* -- */}
<ul>
    <h2>Need help</h2>
    <li>FAQ</li>
    <li>Cancellation Refund Policy</li>
    <li>Privacy Policy</li>
    <li>Terms and Conditions</li>
    <li>Customer and Conditions</li>
</ul>
{/* --- */}
<ul>
    <h2>More Info</h2>
    <li>Corporate Cakes</li>
    <li>Coupons & Offers</li>
</ul>
{/* --- */}
</div>


</header>


</div>



)


}

export default Header;