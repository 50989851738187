import React from "react";
import './Address.css'
import Navbar from "../headers_footer/navbar";


const Address = () => {

return (

<div>

<Navbar></Navbar>


<h1>Delivery Details
</h1>

{/* ----------------------- */}
<div className="form_address">
    
<span>
<div className="flex_add">
<input type="checkbox" placeholder="" className="chck_bx"></input> 
<p>Save my Address</p>
</div>

{/* --- */}
<div className="flex_add">
<div>
<p>Mr.</p>
<select>
    <option>Mr.</option>
    <option>Ms.</option>
</select>
</div>
{/* --- */}
<div>
<p>Name</p>
<input type="" placeholder="Name"></input>
</div>
</div>
{/* ---- */}
<div className="flex_add">
<div>
<p>Country</p>
<input placeholder="Country"></input> 
</div>
{/* -- */}
<div>
<p>Pincode</p>
<input placeholder="Pincode/Zipcode"></input> <br></br>
</div>
</div>
{/* --- */}
<div className="flex_add">
<div>
<p>City</p>
<input placeholder="City"></input> 
</div>
{/* -- */}
<div>
<p>State</p>
<input placeholder="State"></input>  
</div>

</div>
{/* --- */}
<div className="flex_add">
    {/* --- */}
<div>
<p>Mobile number</p>
<input placeholder="Mobile number"></input> 
</div>
{/* -- */}
<div>
<p>Alternative number</p>
<input placeholder="Alternative number"></input> 
</div>
{/* -- */}
</div>
{/* --- */}
<p>Email Id</p>
<input placeholder="Email id"></input>
</span>

<img src="https://cdn.igp.com/raw/upload/assets/images/World%20Map@3x.png" className="img_form"></img>

</div>

{/* ---------------------- */}
</div>


)


}

export default Address;