import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './Cart.css';
import Navbar from '../headers_footer/navbar';
import { removeFromCart } from '../action/action';
import axios from 'axios';  

const Cart = ({ cart, removeFromCart }) => {

  const [loggedInUser, setLoggedInUser] = useState(null);
  
  const [quantities, setQuantities] = useState([]);
  const [productData, setProductData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    const storedUser = localStorage.getItem('loggedInUser');
    if (storedUser) {
      setLoggedInUser(JSON.parse(storedUser));
    }

    if (location.state && location.state.loggedInUser) {
      const user = location.state.loggedInUser;
      setLoggedInUser(user);
      localStorage.setItem('loggedInUser', JSON.stringify(user));
    }
  }, [location.state]);

  useEffect(() => {
    const storedQuantities = JSON.parse(localStorage.getItem('quantities')) || [];
    const initialQuantities = cart.map((_, index) => storedQuantities[index] || 1);
    setQuantities(initialQuantities);
  }, [cart]);


  useEffect(() => {
    if (quantities.length === cart.length) {
      localStorage.setItem('quantities', JSON.stringify(quantities));
    }
  }, [quantities, cart]);


  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await axios.get('http://localhost:3001/fetchProductslist');
        setProductData(response.data);
      } catch (error) {
        console.log('Error fetching products:', error);
      }
    };

    fetchProductData();
  }, []);

  
  
  const totalAmount = cart.reduce((sum, item, index) => sum + item.price * quantities[index], 0);
  const totalProducts = cart.length === 0 ? 0 : quantities.reduce((sum, quantity) => sum + quantity, 0);

  const handleRemove = (index) => {
    removeFromCart(index);
    const newQuantities = quantities.filter((_, i) => i !== index);
    setQuantities(newQuantities);
    localStorage.setItem('quantities', JSON.stringify(newQuantities));
  };

  const handleQuantityChange = (index, change) => {
    const newQuantities = [...quantities];
    newQuantities[index] = Math.max(1, newQuantities[index] + change);
    setQuantities(newQuantities);
    localStorage.setItem('quantities', JSON.stringify(newQuantities));
  };

  const asyncProceed = () => {
    navigate('/Address');
  };

  
  const getProductImagePath = (productId) => {
    const product = productData.find(p => p.id === productId);
    return product ? `http://localhost:3001${product.file_path}` : '';
  };

  return (
    <div>
      <Navbar />
      {cart && cart.length > 0 ? (
        <ul className='flx_cart'>
          {cart.map((item, index) => (
            <li key={index} style={{ listStyle: 'none' }} className='cart_flex'>
              <div className='flex_about'>
                <h3>{item.name}</h3>
                <img
                  src={getProductImagePath(item.id)}
                  alt={item.name}
                  className='cart-item-image'
                />
                <li>Price: ₹{item.price}</li> 
              </div>
              <div className='second_contain'>
                <div>
                  <li><span>₹{item.price * quantities[index]}</span></li>
                  <i onClick={() => handleRemove(index)} className='fa fa-trash'></i>
                </div>
                <div className='quantity_update'>
                  <button onClick={() => handleQuantityChange(index, -1)}>-</button>
                  <span>{quantities[index]}</span>
                  <button onClick={() => handleQuantityChange(index, 1)}>+</button>
                </div>
              </div>
            </li>
          ))}
          <div className='flex_amount'>
            <h1>Total Products: <span>{totalProducts}</span> | Total Amount: Rs <span>{totalAmount}</span></h1>
            <button onClick={asyncProceed}>Proceed To CheckOut</button>
          </div>
        </ul>
      ) : (
        <div>
          <img src='https://cdn.igp.com/raw/upload/assets/svg-icons/empty-cart-new.svg' alt='Empty Wishlist' className='cart-listImg' />
          <div className='div_flex'>
            <h1>Oops!</h1>
            <p>Your wishlist seems to be empty. <br /> Let's find something for you.</p>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (index) => dispatch(removeFromCart(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
